<template>
<div>
  <h1>Sentry</h1>
  <button class="btn btn-primary" @click="error">Error</button>
  <h1>DB Log</h1>
  Room: {{ roomId }}<br/>
  <button class="btn btn-primary" @click="testDbLog">Test DBLog</button>
</div>
</template>

<script>
import { getLog, setDbLog } from "@/services/log"
let log = getLog("test");

export default {
  data() {
    return {
      roomId: null,
    };
  },
  mounted() {
    this.roomId = this.$route.query.roomId;
  },
  methods: {
    error() {
      throw "Forced Error";
    },
    testDbLog() {
      setDbLog(log, this.roomId, "error-test");
      log.log("-----------------------------------------------------------")
      log.log("one param", {hello:"world"});
      log.log("two params", {hello:"world"}, {"foo":"bar"});
      try {
        throw "what?";
      } catch (e) {
        log.log("error type=", typeof(e));
        log.log("error", e);
      }
      try {
        throw Error("What?");
      } catch (e) {
        log.log("error type=", typeof(e));
        log.log("error", e);
      }
    }
  }
}
</script>

<style>

</style>